import React, { useRef, useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";
import "./carousels.css";

// import AppleComputers from '../images/Apple display.png'

import { Box, Container, Grid, Skeleton, Typography } from "@mui/material";
import { web_landingpage } from "./landingState";

const Carousels = () => {
  const swiperRef = useRef(null);

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const [resendTimer, setResendTimer] = useState(55);

  // Effect to handle countdown
  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendTimer]);
  //Profile

  // import AppleComputers from '../images/Apple display.png'

  const [carousel, setCarousel] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    web_landingpage()
      .then((res) => {
        const data = res.data;
        if (data && data.length > 0) {
          const desiredSection = data.find(
            (section) => section.template_type === "carousel"
          );
          if (desiredSection) {
            setCarousel(desiredSection);
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after data is fetched
      });
  }, []);

  if (!carousel) return null;

  return (
    <div>
      <div className="container">
        <Typography
          variant="h4"
          sx={{
            marginBottom: "5rem",
          }}
        >
          {/* Don't Miss Latest Gospel & <br />
          Prophecies to Pray */}
          {carousel.title}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter !important",
            fontSize: "18px",
            fontWeight: 400,
            lineHeight: "32px",
            textAlign: "center",
            color: "rgba(255, 255, 255, 1)",
            marginTop: "-75px",
          }}
        >
          {carousel.title2}
          {/* The Biggest Edification platform for end time Generation */}
        </Typography>
        <div
          style={{
            width: "450px",
            height: "400px",
            justifyContent: "center",
            display: "flex",
            left: "-5%",
            gap: "0px",
            marginTop: "10rem",
            opacity: "0px",
            background: "rgba(118, 64, 232, 1)",
            filter: "blur(180px)",
            position: "absolute",
          }}
        ></div>

        <Container></Container>

        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          ref={swiperRef}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 500,
            modifier: 2.5,
            slideShadows: true,
            crossFade: true,
          }}
          pagination={{ el: ".swiper-pagination", clickable: true }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container"
          breakpoints={{
            0: {
              slidesPerView: 3,
              centeredSlides: true,
            },
            768: {
              slidesPerView: 3,
              centeredSlides: true,
            },
          }}
        >
           {loading
            ? // Show Skeleton loader while loading
              Array.from(new Array(10)).map((_, index) => (
                <Grid item xs={12} sm={2} md={2} lg={2} key={index}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "200px",
                      position: "relative",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </Grid>
              ))
            :
          carousel.list.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={image}
                alt={`slide_image_${index + 1}`}
                className="custom-slide-img" // Apply the custom class here
                // style={{
                //   borderRadius: "34.05px !important",
                // }}
                // onClick={() => handleClick(image.content_id, image.content_type_id)}
              />
            </SwiperSlide>
          ))}

          <div className="slider-controler">
            <div
              className="swiper-button-prev slider-arrow"
              onClick={handlePrev}
            >
              <ion-icon
                name="arrow-back-outline"
                style={{ color: "white" }}
              ></ion-icon>
            </div>
            <div
              className="swiper-button-next slider-arrow"
              onClick={handleNext}
            >
              <ion-icon
                name="arrow-forward-outline"
                style={{ color: "white" }}
              ></ion-icon>
            </div>
            <div className="swiper-pagination"></div>
          </div>
        </Swiper>
      </div>
    </div>
  );
};

export default Carousels;
