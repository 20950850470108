import axiosInstance from '../../axiosInstance';
// import { apiEndpoint } from "../../components/data/config";

// export const view_movies = async () => {
//   const config = {
//     method: 'get',
//     url: `${apiEndpoint}/api/app_home_viewall/2`,
//   };
//   return axiosInstance(config); // Use axiosInstance instead of axios
// };





import { apiEndpoint } from "../../components/data/config";

export const view_movies = async () => {
  const config = {
    method: "get",
    url: `${apiEndpoint}/api/app_home_viewall/2`,
    headers: {
      // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,

      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config);
};
