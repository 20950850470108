import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { apiEndpoint } from "../../components/data/config";
import "../../styles.css"; // Import your custom CSS file here

import Content from "../landingPage/content";
import Carousels from "../landingPage/carousels";
import Card from "../landingPage/card";

import { Grid, Button, Skeleton, Box } from "@mui/material";

import { web_landingpage } from "./landingState";
import { useNavigate } from "react-router-dom";
import playButton from "../images/Vector (1).png";

import axios from "axios";
import five from "../images/five.png";
import four from '../images/400.png'
import CryptoJS from "crypto-js";
import {
  GoogleAuthProvider,
  signInWithPopup,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  OAuthProvider,
  signInWithRedirect,
} from "firebase/auth";

import { auth } from "../../fireBase/setup"; // Import the initialized auth object

import AuthDialog from "../../components/AuthDialog";
import CustomSnackbar from "../../components/Snackbar/snackbar";
function SampleNextArrow(props) {
  return <div style={{ display: "none" }} />;
}

function SamplePrevArrow(props) {
  return <div style={{ display: "none" }} />;
}

const Banner = () => {
  const [bannerData, setBannerData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarImage, setSnackbarImage] = React.useState(""); // Dynamic image URL


  const [open, setOpen] = React.useState(false);
  const [code, setCode] = React.useState(false);
  const [error500, setError500] = useState(false); // Add error state for 500 status
  const [error404, setError404] = useState(false); // Add error state for 500 status
  const [loading, setLoading] = useState(true); // Add loading state
  const handleCloseDialog = () => {
    setOpen(false);
  };
  // eslint-disable-next-line no-unused-vars
  const [isUnauthorized, setIsUnauthorized] = useState(false);
  const handleCloseCode = () => {
    setCode(false);
  };

  const [phone, setPhone] = useState("+91");
  const [verificationCode, setVerificationCode] = useState([
    "",
    "",
    "",
    "",
    "",
    "",
  ]);
  const [resendTimer, setResendTimer] = useState(55);

  // Effect to handle countdown
  useEffect(() => {
    if (resendTimer > 0) {
      const timer = setInterval(() => {
        setResendTimer((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [resendTimer]);
  //Profile
  const appleLogin = async () => {
    const provider = new OAuthProvider('apple.com'); // Initialize the Apple OAuth provider
  
    try {
      // Trigger Apple Sign-In popup
      const result = await signInWithRedirect(auth, provider);
      
      // Get user info from Apple result
      const user = result.user;
      const token = await user.getIdToken(); // Get Firebase token
  
      // Prepare user data to send to your backend (if needed)
      const userData = {
        name: user.displayName || "Anonymous", // Apple does not always provide the full name
        email: user.email,
        phone_number: user.phoneNumber,
      };
  
      // Post user data to your custom backend API
      const response = await axios.post(
        `${apiEndpoint}/api/app_social_login`, // Your custom backend endpoint
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send Firebase token to the backend
          },
        }
      );
  
      if (response.status === 200) {
      
  
        // Extract token from backend response
        const { token: backendToken } = response.data.data;
  
        if (backendToken) {
          // Store the backend token in localStorage
          localStorage.setItem("auth_token", backendToken);
        
  
          // Redirect user or close login modal
          setOpen(false);
          navigate("/homePage");
        } else {
          console.error("No token found in response data.");
        }
      } else {
        console.error("Failed to login", response.status, response.data);
      }
    } catch (error) {
     
      if (error.response && error.response.status === 401) {
        setIsUnauthorized(true); // Show sign-in button when unauthorized
        // handleClickSnackbar("Session expired. Please sign in again.");
      }
    }
  };
  const navigate = useNavigate();
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false); // Close the snackbar
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 1500, // Increased speed for smoothness
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true, // Enables fade effect for smoother transitions
    pauseOnHover: false, // Disable pause on hover
    responsive: [
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
        
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
        
      },
    ],
  };
  useEffect(() => {
    web_landingpage()
      .then((res) => {
        const data = res.data;
        if (data && data.length > 0) {
          const bannerSection = data.find(
            (section) => section.template_type === "BANNER"
          );
          if (bannerSection && bannerSection.list) {
            setBannerData(bannerSection.list);
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        if (err.response) {
          if (err.response.status === 500) {
              setError500(true); // Handle 500 error
          } else if (err.response.status === 404) {
              setError404(true); // Handle 404 error
          }
          else if(err.response.status === 401){
            localStorage.removeItem("auth_token");
            navigate("/");
    
            // Optionally reload the page to ensure UI state updates
            window.location.reload();
          }
      }
      })
      .finally(() => {
        setLoading(false); // Set loading to false after data is fetched
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  const encryptQueryString = (queryString) => {
    const secretKey = "your-secret-key"; // Use a secure key here
    const encrypted = CryptoJS.AES.encrypt(queryString, secretKey).toString();
    return encodeURIComponent(encrypted); // Make it URL-safe
  };

  const handleClick = (content_id, content_type_id) => {
    const token = localStorage.getItem("auth_token"); // Check if token exists in localStorage

    if (token) {
      const queryString = `content_id=${content_id}&content_type_id=${content_type_id}`;

      // Encrypt the query string
      const encryptedQuery = encryptQueryString(queryString);

      // Navigate to the new page with the encrypted query string
      navigate(`/videoPlayer?data=${encryptedQuery}`);
    } else {
      // User is not logged in, show alert and stay on the page
      setOpen(true);
    }
    // Construct the query string
  };

  const login = async () => {
    const provider = new GoogleAuthProvider();

    try {
      // Trigger Google Sign-In popup
      const result = await signInWithPopup(auth, provider);

      // Get user info from Google result
      const user = result.user;
      const token = await user.getIdToken(); // Firebase token if needed for future use

      const userData = {
        name: user.displayName,
        email: user.email,
        phone_number: user.phoneNumber,
        birth_date: "1990-01-01", // Example birth date, change as required
      };

      // Post user data to your custom backend API
      const response = await axios.post(
        `${apiEndpoint}/api/app_social_login`,
       
      
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Optional: if you want to pass the Firebase token
          },
        }
      );

      if (response.status === 200) {
        // Check the structure of response.data
        console.log("Login response data:", response.data);

        // Access the token from the response data
        const { token: backendToken } = response.data.data; // Adjust based on actual response structure

        if (backendToken) {
          // Store the token in localStorage
          localStorage.setItem("auth_token", backendToken);
          console.log("Token stored in local storage:", backendToken);

          // Handle successful login response
          setOpen(false);
          navigate("/homePage");
          // alert("Login is successful");
        } else {
          console.error("No token found in response data.");
        }
      } else {
        // Handle non-200 status
        console.error("Failed to login", response.status, response.data);
      }
    } catch (error) {
      console.error("Error during Google Sign-In:", error);
    }
  };



  const handleClickSnackbar = (message, imgSrc) => {
    setSnackbarMessage(message);
    setSnackbarImage(imgSrc); // Set the dynamic image
    setSnackbarOpen(true);
  };

// Navigate to /homePage once the login is successful

const [mobileNumber, setMobileNumber] = useState(''); // example mobile number
  const [otp, setOtp] = useState( "",
      "",
      "",
      "",
      "",
      "",);
  const [sessionId, setSessionId] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [verificationStatus, setVerificationStatus] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [loginData, setLoginData] = useState(null);
  // const [isUnauthorized, setIsUnauthorized] = useState(false);
  const sendOtp = async () => {
    try {
      const response = await axios.post(
        // 'https://admin.evangel.stream/api/send_user_otp',
        `${apiEndpoint}/api/send_user_otp`,
        { mobile_number: mobileNumber }
      );

      if (response.data.Status === 'Success') {
        setSessionId(response.data.Details);
        setCode(true);
     
        handleClickSnackbar("OTP sent successfully!");
      } else {
        alert('Failed to send OTP');
        handleClickSnackbar("OTP is not sent!");
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      alert('Error sending OTP');
    }
  };

  // Step 2: Function to verify OTP
  // const verifySessionOtp = async () => {
  //   if (!sessionId) {
  //     alert('Session ID is missing. Please send OTP first.');
  //     return;
  //   }
  
  //   try {
  //     // Convert the OTP array to a single string
  //     const otpString = otp.join(''); // Join OTP array elements into a single string
  
  //     const response = await axios.post(
  //       // 'https://admin.evangel.stream/api/verify_user_otp',
  //       `${apiEndpoint}/api/verify_user_otp`,
  //       {
  //         session_id: sessionId,
  //         user_otp: otpString,  // Send OTP as a string
  //       }
  //     );
  //     console.log(response, "ressssss");
  
  //     if (response.data.Status === 'Success') {
  //       setVerificationStatus('OTP Verified Successfully');
        
  //       loginWithOtp();
  //     //  navigate("/homePage");  
  //       // Navigate to home page  // Call the login function after successful OTP verification
  //     }
  //      else {
  //       setVerificationStatus('OTP Verification Failed');
  //       handleClickSnackbar("Invalid otp please try again.");
  //     }
  //   } catch (error) {
  //     console.error('Error verifying OTP:', error);
  //     setVerificationStatus('Error verifying OTP');
  //   }
  // };

  const verifySessionOtp = async () => {
    if (!sessionId) {
      alert('Session ID is missing. Please send OTP first.');
      return;
    }
  
    try {
      // Convert the OTP array to a single string
      const otpString = otp.join(''); // Join OTP array elements into a single string
  
      const response = await axios.post(
        `${apiEndpoint}/api/verify_user_otp`,
        {
          session_id: sessionId,
          user_otp: otpString,  // Send OTP as a string
        }
      );
  

  
      if (response.data.Status === 'Success') {
        setVerificationStatus('OTP Verified Successfully');
        await loginWithOtp(); // Call the login function after successful OTP verification
      } else {
        setVerificationStatus('OTP Verification Failed');
        handleClickSnackbar("Invalid OTP. Please try again."); // Show snackbar for invalid OTP
      }
    } catch (error) {
      console.error('Error verifying OTP:', error);
      
      if (error.response) {
        if (error.response.status === 400) {
          // Handle 400 error specifically
          handleClickSnackbar("Invalid OTP. Please try again.");
        } else if (error.response.status === 401) {
          setIsUnauthorized(true);
          handleClickSnackbar("Session expired. Please sign in again.");
        } else {
          handleClickSnackbar("Error verifying OTP. Please try again later.");
        }
      } else {
        handleClickSnackbar("Network error, please check your connection.");
      }
  
      setVerificationStatus('Error verifying OTP');
    }
  };
  

const loginWithOtp = async () => {
  const otpString = otp.join("");

  if (otpString.length === 6) {
    try {
      const payload = { phone_number: mobileNumber };
      const response = await axios.post(
        `${apiEndpoint}/api/app_otp_login`,
        payload
      );
      console.log("Response from login:", response);

      if (response.status === 200 && response.data.status === 'Success') {
        const { token: backendToken } = response.data.data;
        if (backendToken) {
          localStorage.setItem("auth_token", backendToken);
          console.log("Token stored:", backendToken);
          handleClickSnackbar("Logged in successfully!");
        
        navigate("/homePage");  // Navigate to home page
          
          // Set login state to true to trigger navigation
          setIsLoggedIn(true);
          navigate("/homePage");  // Navigate to home page
        } else {
          handleClickSnackbar("Failed to Login!");
        }
      } else {
        handleClickSnackbar("Failed to Login!");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setIsUnauthorized(true);
        handleClickSnackbar("Session expired. Please sign in again.");
      } else {
        console.error('Error during OTP login:', error);
        handleClickSnackbar("Error during OTP login");
      }
    }
  } else {
    handleClickSnackbar("OTP is not valid. Please try again.");
  }
};



  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form refresh
    verifySessionOtp();
  };
  

  const handleResendCode = () => {
    // Logic to resend OTP
    console.log("Resending OTP...");
    // Reset the timer
    setResendTimer(55);
  };

  const handleInputChange = (index, e) => {
    const value = e.target.value;

    if (value.length === 1) {
      // Update OTP array and move focus to next input
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      focusNext(index);
    } else if (value.length === 0) {
      // Update OTP array and move focus to previous input
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);
      focusPrevious(index);
    }
  };
  const inputRefs = useRef([]);

  // Function to focus the next input field
  const focusNext = (index) => {
    if (index < verificationCode.length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  // Function to focus the previous input field
  const focusPrevious = (index) => {
    if (index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <>
      <div
        style={{
          // width: "100vw",
          maxWidth: "100vw",
          minHeight: "100vh", // Changed height to minHeight to ensure the content can overflow if needed
          backgroundColor: "#1b0a28",
          position: "relative",
          overflowX: "hidden",
        }}
      >
          {/* Background image for 500 error */}
          {error500 && (
                <div
                    style={{
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100vh", // Full viewport height to center the image vertically
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 10, // Ensure it appears above the normal content
                        display: "flex",
                    }}
                >
                    <img
                        src={five}
                        alt="Error 500"
                        style={{ maxWidth: "100%", height: "auto" }}
                    />
                </div>
            )}

            {/* Render 404 Error Image */}
            {error404 && (
                <div
                    style={{
                        position: "absolute",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100vh", // Full viewport height to center the image vertically
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        zIndex: 10, // Ensure it appears above the normal content
                        display: "flex",
                    }}
                >
                    <img
                        src={four}
                        alt="Error 404"
                        style={{ maxWidth: "100%", height: "auto" }}
                    />
                </div>
            )}
              {!error500 && !error404 && (
                <>
        <div style={{ position: "relative" }}>
          <Slider {...settings}>
          {loading
            ? // Show Skeleton loader while loading
              Array.from(new Array(10)).map((_, index) => (
                <Grid item xs={12} sm={2} md={2} lg={2} key={index}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "200px",
                      position: "relative",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </Grid>
              ))
            :
            bannerData.map((item, index) => (
              <div key={index} style={{ position: "relative" }}>
                {/* <div style={overlayStyle}></div> Overlay div */}
                <Grid
                  sx={{
                    width: "3%",
                    height: "560px",
                    borderRadius: "560px",
                    top: "-148",
                    left: "-91.86", // Position from the left edge of the image
                    // Center the overlay horizontally and vertically
                    background: "rgba(118, 64, 232, 0.5)", // Semi-transparent overlay color
                    position: "absolute", // Position absolutely within the parent container
                    filter: "blur(140px)",
                  }}
                />
                <div style={data}></div>

                <div style={overlayStyles}></div>
                <Grid
                  sx={{
                    display: {
                      xs: "none",
                      sm: "flex",
                      md: "flex",
                      lg: "flex",
                      xl: "flex",
                    },
                  }}
                >
                  <img
                    src={item.slider_image} // Assuming the image URL is in `slider_image`
                    alt={`Banner${index}`}
                    style={{ width: "100%", height: "auto" }}
                  />
                </Grid>
                <Grid
                  sx={{
                    display: {
                      xs: "flex",
                      sm: "none",
                      md: "none",
                      lg: "none",
                      xl: "none",
                    },
                    width: {
                      xs: "100%", // Full width on small screens
                    },
                    // height: {
                    //   xs: "auto",  // Adjust height based on the width
                    // },
                  }}
                >
                  <div
                    style={{
                      position: "relative",
                      overflow: "hidden",
                      cursor: "pointer",
                      borderRadius: "8px",
                      width: "100%",
                      height: "0",
                      paddingBottom: "150.25%", // This maintains a 16:9 aspect ratio
                    }}
                  >
                    <img
                      src={item.responsive_image}
                      alt={`Banner ${index}`}
                      style={{
                        position: "absolute", // Make the image fill the container
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", // Image covers the container
                      }}
                    />
                  </div>
                </Grid>

                {/* <p>ஜே</p> */}
                <Grid container>
                  <Grid
                    sx={{
                      position: "absolute",
                      top: "28%",
                      width: "638px",
                      height: "309px",

                      marginLeft: "11rem",
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "none",
                        xl: "flex",
                      },
                    }}
                  >
                    <img src={item.image_title} alt="img"></img>
                  </Grid>
                  <Grid
                    sx={{
                      position: "absolute",
                      top: "28%",
                      marginLeft: "5rem",
                      width: "638px",
                      height: "220px",

                      display: {
                        xs: "none",
                        sm: "none",
                        md: "none",
                        lg: "flex",
                        xl: "none",
                      },
                    }}
                  >
                    <img src={item.image_title} alt="img"></img>
                  </Grid>

                  <Grid
                    sx={{
                      position: "absolute",
                      top: "28%",
                      marginLeft: "5rem",
                      //  height:'40%',
                      width: "638px",
                      height: "190px",

                      display: {
                        xs: "none",
                        sm: "none",
                        md: "flex",
                        lg: "none",
                        xl: "none",
                      },
                    }}
                  >
                    <img src={item.image_title} alt="img"></img>
                  </Grid>

                  <Grid
                    sx={{
                      position: "absolute",
                      top: "30%",
                      marginLeft: "2rem",
                      //  height:'40%',
                      width: "638px",
                      height: "150px",

                      display: {
                        xs: "none",
                        sm: "flex",
                        md: "none",
                        lg: "none",
                        xl: "none",
                      },
                    }}
                  >
                    <img src={item.image_title} alt="img"></img>
                  </Grid>

                  <Grid
                    sx={{
                      position: "absolute",
                      top: "40%",
                      // marginLeft: "1rem",
                      height: "106px",
                      marginTop: "175px",
                      left:"-20px",

                      display: {
                        xs: "flex",
                        sm: "none",
                        md: "none",
                        lg: "none",
                        xl: "none",
                      },
                    }}
                  >
                    <img src={item.responsive_title} alt="img"></img>
                  </Grid>

                  {/* <Grid classes="home_button_gradients"></Grid> */}
                  <Grid
                        sx={{
                          display: {
                            xs: "none",
                            sm: "flex",
                            md: "flex",
                            lg: "flex",
                            xl: "flex",
                          },
                       
                          // width: "300px",
                          width:{
                            md:"150px",
                            lg:"300px",
                            xl:"300px",
                            sm:"150px"
                          },
                          //   height: "680.82px",
                          height: "993.45px",
                          
                          border: "1px",
                          background:
                            "linear-gradient(170.07deg, rgba(188, 46, 255, 0.72) 9.43%, rgba(128, 76, 238, 0) 117.55%)",

                          position: "absolute",
                          zIndex: "9.999999",
                          right: "200px",
                          transform: "rotate(87.26deg)",
                          filter: "blur(80.56px)",
                          // marginTop: "-50rem",
                          marginTop:{
                            md:"-39rem",
                            lg:"-50rem",
                            xl:"-50rem",
                            sm:"-37rem"

                          },
                          gap: "0px",
                          opacity: "9px",
                        }}
                      />
                  <Grid
                    sx={{
                      position: "absolute",

                      transform: "translate(-50%, -50%)",
                      zIndex: 2,
                      top: { xs: "60%", sm: "75%", md: "75%", lg: "68%" },
                      left: { xs: "50%", sm: "75%", md: "75%", lg: "76%" },
                    }}
                  >
                    <Button
                      sx={{
                        background:
                          "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
                        color: "#fff",
                        border: "1.597px solid transparent",
                        borderRadius: "60px",
                        textTransform: "initial",
                        fontSize: {
                          xs: "16px",
                          sm: "25px",
                          md: "25px",
                          lg: "25px",
                        },
                        fontWeight: 700,
                        lineHeight: "38.73px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        fontFamily: "Inter !important",
                        boxShadow: "0px 20px 60px 0px rgba(27, 12, 87, 1)",
                        position: "absolute",
                        opacity: 9.85,
                        zIndex: 9, // Higher zIndex for button
                        height: {
                          xs: "40px",
                          sm: "55px",
                          md: "55px",
                          lg: "55px",
                        },
                        backdropFilter: "blur(1px)",
                        padding: {
                          xs: "0px 25px",
                          sm: "0px 35px",
                          md: "0px 45px",
                          lg: "0px 45px",
                        },
                      }}
                      onClick={() =>
                        handleClick(item.content_id, item.content_type_id)
                      }
                    >
                      <img
                        src={playButton}
                        alt="playButton"
                        style={{ marginRight: "10px" }}
                      />
                      Watch
                    </Button>
                  </Grid>
                </Grid>

                {/* </div> */}
              </div>
            ))}
          </Slider>
        </div>

        <Card />
        {/* <RecentlyAdded />  */}

        <Carousels />

        <Content />
        </>)}
      </div>
    
      
      <AuthDialog
        open={open}
        handleCloseDialog={handleCloseDialog}
        // handleSend={handleSend}
        phone={phone}
        setPhone={setPhone}
        code={code}
        handleCloseCode={handleCloseCode}
        verificationCode={verificationCode}
        setVerificationCode={setVerificationCode}
        otp={otp}
        mobileNumber={mobileNumber}
        setMobileNumber={setMobileNumber}
        setOtp={setOtp} // Pass setOtp here
        // handleInputChange={handleInputChange}
        // verifyOtp={verifyOtp}
        resendTimer={resendTimer}
        handleResendCode={handleResendCode}
        login={login}
        appleLogin={appleLogin}
        sendOtp={sendOtp}
        verifySessionOtp={verifySessionOtp}
        loginData={loginData}

        
      />
       <CustomSnackbar
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        autoHideDuration={5000}
        imgSrc={snackbarImage} // Dynamically pass the image URL
      />
    </>
  );
};

export default Banner;

const data = {
  width: "100%",
  // maxWidth:'100vw',
  height: "239px",
  top: "-148",
  left: "-91.86", // Position from the left edge of the image
  // Center the overlay horizontally and vertically
  // background: 'rgba(118, 64, 232, 0.5)', // Semi-transparent overlay color
  background:
    "linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0.9%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 86.4%)",
  position: "absolute", // Position absolutely within the parent container
  filter: "blur(20px)",
};

const overlayStyles = {
  width: "100%",
  height: "50%",
  top: "50%",

  // left: "-91.86",

  background:
    "linear-gradient(180.1deg, rgba(27, 10, 40, 0) 44.79%, rgba(27, 10, 40, 0.401553) 56.48%, rgba(27, 10, 40, 0.674889) 67.63%, #1B0A28 86.49%)",
  position: "absolute",
  filter: "blur(1px)",
};
