import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Grid, Skeleton, Stack, Typography } from "@mui/material";

import { view_Series } from "./tvSeriesState";
import { useNavigate } from "react-router-dom";
import four from "../images/400.png";
import five from "../images/five.png";
import CryptoJS from "crypto-js";

const TvSeries = () => {
  const [hoverStates, setHoverStates] = useState({});
  const navigate = useNavigate();

  const encryptQueryString = (queryString) => {
    const secretKey = "your-secret-key"; // Use a secure key here
    const encrypted = CryptoJS.AES.encrypt(queryString, secretKey).toString();
    return encodeURIComponent(encrypted); // Make it URL-safe
  };

  const handleClick = (content_id, content_type_id) => {
    // Construct the query string
    const queryString = `content_id=${content_id}&content_type_id=${content_type_id}`;

    // Encrypt the query string
    const encryptedQuery = encryptQueryString(queryString);

    // Navigate to the new page with the encrypted query string
    navigate(`/videoPlayer?data=${encryptedQuery}`);
  };

  const [error500, setError500] = useState(false); // Add error state for 500 status
  const [error404, setError404] = useState(false); // Add error state for 500 status
  const [genres, setGenres] = useState({});
  const [loading, setLoading] = useState(true); // Add loading state
  useEffect(() => {
    view_Series()
      .then((res) => {
        const data = res.data.list;
        const genresObject = {};

        data.forEach((section) => {
          if (section.content) {
            genresObject[section.title] = section.content;
          }
        });

        setGenres(genresObject);
        setActiveButton(Object.keys(genresObject)[0] || "All"); // Default to the first genre or 'All'
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        if (err.response) {
          if (err.response.status === 500) {
            setError500(true); // Handle 500 error
          } else if (err.response.status === 404) {
            setError404(true); // Handle 404 error
          } else if (err.response.status === 401) {
            localStorage.removeItem("auth_token");
            navigate("/");

            // Optionally reload the page to ensure UI state updates
            window.location.reload();
          }
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false after data is fetched
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonStyle = (theme) => ({
    background: "#40374D",
    color: "#FFB7FF",
    height: "40px",
    borderRadius: "12px",
    textTransform: "capitalize", // Capitalize first letter and lowercase the rest
    fontSize: "15px",
    fontFamily: "Inter !important",
    padding: "19.06px 20.65px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      background: "#40374D",
    },

    [theme.breakpoints.down("lg")]: {
      // For devices <= 1024px
      fontSize: "9px",
      padding: "15px 18px",
    },
    [theme.breakpoints.down("md")]: {
      // For devices <= 768px
      fontSize: "12px",
      padding: "12px 14px",
    },
    [theme.breakpoints.down("sm")]: {
      // For devices <= 480px
      fontSize: "10px",
      padding: "10px 12px",
    },
  });
  // Responsive styling
  // "@media (max-width: 1024px)": {
  //   fontSize: "14px",
  //   padding: "15px 16px",
  // },
  // "@media (max-width: 768px)": {
  //   fontSize: "12px",
  //   padding: "12px 14px",
  // },
  // "@media (max-width: 480px)": {
  //   fontSize: "10px",
  //   padding: "10px 12px",
  // },

  const activeButtonStyle = {
    background: "#BD25C0",
    color: "#FFB7FF",
    "&:hover": {
      background: "#BD25C0",
    },
  };
  const [activeButton, setActiveButton] = useState("All");

  const capitalizeFirstLetter = (text) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  return (
    <div
      style={{
        maxWidth: "100vw",
        minHeight: "100vh",
        backgroundColor: "#1b0a28",
        position: "relative",
        overflow: "hidden", // Ensure content doesn’t overflow
      }}
    >
      {/* Background image for 500 error */}
      {error500 && (
        <div
          style={{
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh", // Full viewport height to center the image vertically
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            zIndex: 10, // Ensure it appears above the normal content
            display: "flex",
          }}
        >
          <img
            src={five}
            alt="Error 500"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      )}

      {/* Render 404 Error Image */}
      {error404 && (
        <div
          style={{
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh", // Full viewport height to center the image vertically
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            zIndex: 10, // Ensure it appears above the normal content
            display: "flex",
          }}
        >
          <img
            src={four}
            alt="Error 404"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      )}
      {/* Main content */}
      {!error500 && !error404 && (
        <>
          {/* <Grid classes="data"></Grid> */}
          <div
            style={{
              width: "100%",

              height: "236px",
              top: "-70%",
              left: "-91.86", // Position from the left edge of the image

              background:
                "linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0.9%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 86.4%)",
              position: "absolute", // Position absolutely within the parent container
              filter: "blur(12px)",
            }}
          />

          <Grid
            sx={{
              position: "relative",
              paddingTop: { xs: "8rem", sm: "10rem", md: "12rem", lg: "12rem" },
              paddingLeft: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
              paddingRight: { xs: "2rem", sm: "2rem", md: "5rem", lg: "10rem" },
            }}
          >
            {/* <Grid classes="content_gradient_sub"></Grid>
            <Grid classes="recent_gradient"></Grid> */}
            <div
              style={{
                width: "549px",

                borderRadius: "600.667px",

                height: "600.45px",

                left: "0%",
                top: "0%",

                gap: "0px",
                opacity: "0px",
                background: "rgba(118, 64, 232, 1)",
                filter: "blur(170px)",

                position: "absolute",
              }}
            />

            <Grid
              container
              columns={{ xs: 4, sm: 8, md: 12 }}
              sx={{ position: "relative" }}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                sx={{ paddingBottom: "2rem", position: "relative" }}
              >
                <Typography variant="subTitle1">Tv Series</Typography>
              </Grid>
            </Grid>

            <Divider
              style={{
                border: "1px solid #64748B",
                position: "relative",
              }}
            />

            <div>
              <Stack
                direction={{ xs: "column", sm: "column", md: "row", lg: "row" }}
                spacing={{ xs: 1, sm: 2, md: 2 }}
                sx={{
                  paddingTop: "2rem",
                  paddingBottom: "2rem",
                  display: {
                    xs: "none", // Hide on extra-small screens
                    sm: "none", // Hide on small screens
                    md: "flex", // Hide on medium screens
                    lg: "flex", // Hide on large screens
                    xl: "flex", // Display only on extra-large screens
                  },
                }}
              >
                {Object.keys(genres).map((genreTitle) => (
                  <Button
                    key={genreTitle}
                    sx={(theme) => ({
                      ...buttonStyle(theme),
                      ...(activeButton === genreTitle ? activeButtonStyle : {}),
                    })}
                    onClick={() => setActiveButton(genreTitle)}
                  >
                    {capitalizeFirstLetter(genreTitle)}{" "}
                    {/* Apply the function here */}
                  </Button>
                ))}
              </Stack>
            </div>
            <div>
              <Grid
                container
                spacing={2}
                sx={{
                  paddingTop: "2rem",
                  paddingBottom: "2rem",
                  display: {
                    xs: "flex", // Hide on extra-small screens
                    sm: "flex", // Hide on small screens
                    md: "none", // Hide on medium screens
                    lg: "none", // Hide on large screens
                    xl: "none", // Display only on extra-large screens
                  },
                }}
              >
                {Object.keys(genres).map((genreTitle) => (
                  <Grid
                    item
                    xs={6} // This makes it take up 50% of the width on extra small screens (2 per row)
                    sm={4} // Same for small screens
                    // On medium and larger screens, each button will take the full row width (in line)
                    key={genreTitle}
                  >
                    <Button
                      sx={(theme) => ({
                        ...buttonStyle(theme),
                        ...(activeButton === genreTitle
                          ? activeButtonStyle
                          : {}),
                      })}
                      onClick={() => setActiveButton(genreTitle)}
                    >
                      {capitalizeFirstLetter(genreTitle)}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </div>

            <Box
              sx={{
                textAlign: "center",
                position: "relative",
                paddingTop: { xs: "3rem", sm: "3rem", md: "3rem", lg: "2rem" },
                paddingBottom: {
                  xs: "3rem",
                  sm: "3rem",
                  md: "3rem",
                  lg: "2rem",
                },
              }}
            >
              <Grid
                container
                columnSpacing={{ xs: 2, sm: 1, md: 1, lg: 1.5 }}
                columns={{ xs: 4, sm: 14, md: 14, lg: 14 }}
                rowSpacing={{ xs: 2, sm: 6, md: 5 }}
              >
                 {loading
            ? // Show Skeleton loader while loading
              Array.from(new Array(10)).map((_, index) => (
                <Grid item xs={12} sm={2} md={2} lg={2} key={index}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "200px",
                      position: "relative",
                    }}
                  >
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="100%"
                    />
                  </Box>
                </Grid>
              ))
            : 
                genres[activeButton]?.map((image, index) => (
                  <Grid
                    item
                    xs={2}
                    sm={2}
                    md={2}
                    lg={2}
                    key={index}
                    onClick={() =>
                      handleClick(image.content_id, image.content_type_id)
                    }
                  >
                    <div
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        cursor: "pointer",
                        borderRadius: "8px",
                        width: "100%",
                        height: "0",
                        paddingBottom: "150.25%", // Maintain a 16:9 aspect ratio
                      }}
                      onMouseEnter={() =>
                        setHoverStates((prev) => ({ ...prev, [index]: true }))
                      }
                      onMouseLeave={() =>
                        setHoverStates((prev) => ({ ...prev, [index]: false }))
                      }
                    >
                      <img
                        src={image.thumbnail}
                        alt={`Thumbnail ${index}`}
                        style={{
                          width: "100%",
                          height: "100%",
                          position: "absolute",
                          top: 0,
                          left: 0,
                          objectFit: "cover",
                          transition: "transform 0.3s ease-in-out",
                          transform: hoverStates[index]
                            ? "scale(1.05)"
                            : "scale(1)", // Slight zoom
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          width: "100%",
                          height: "100%", // Full height of the container
                          background:
                            "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 100%)", // Custom gradient
                          color: "white",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end", // Align content to the bottom
                          padding: "0.5rem",
                          opacity: hoverStates[index] ? 1 : 0,
                          transition:
                            "opacity 0.3s ease-in-out, background 0.3s ease-in-out",
                          pointerEvents: "none", // Prevents the overlay from blocking clicks
                          boxSizing: "border-box", // Ensure padding does not affect height calculation
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "8px",
                              sm: "8px",
                              md: "8px",
                              lg: "12px",
                              xl: "20px",
                            },
                            lineHeight: {
                              xs: "8px",
                              sm: "16px",
                              md: "30px",
                              lg: "28px",
                              xl: "30px",
                            },
                            color: "white",
                            padding: 0,
                            margin: 0,
                            fontFamily: "inter !important",
                          }}
                        >
                          {image.content_title}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "8px",
                              sm: "6px",
                              md: "6px",
                              lg: "10px",
                              xl: "12px",
                            },
                            lineHeight: {
                              xs: "8px",
                              sm: "8px",
                              md: "12px",
                              lg: "12px",
                              xl: "20px",
                            },
                            padding: 0,
                            margin: "0",
                            fontFamily: "inter !important",
                            color: "white",
                            display: "-webkit-box",
                            WebkitLineClamp: 3, // Limit to 3 lines
                            WebkitBoxOrient: "vertical", // Set box orientation
                            overflow: "hidden",
                          }}
                        >
                          {image.content_description}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </>
      )}
    </div>
  );
};

export default TvSeries;
