import React, { useState, useRef, useEffect } from "react";
import { Grid, Typography, IconButton, Divider, Box, Skeleton } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import Slider from "react-slick";

import { home_web } from "./homeState";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

const CustomPrevArrow = ({ onClick, disabled }) => (
  <IconButton
    onClick={onClick}
    disabled={disabled}
    style={{
      position: "absolute",
      top: "50%",
      left: "-30px",
      zIndex: 1,
      transform: "translateY(-50%)",
      backgroundColor: "transparent",
      color: disabled ? "gray" : "white",
    }}
  >
    <ArrowBackIosIcon style={{ fontSize: "40px" }} />
  </IconButton>
);

const CustomNextArrow = ({ onClick, disabled }) => (
  <IconButton
    onClick={onClick}
    disabled={disabled}
    style={{
      position: "absolute",
      top: "50%",
      right: "-40px",
      zIndex: 1,
      transform: "translateY(-50%)",
      backgroundColor: "transparent",
      color: disabled ? "gray" : "white",
    }}
  >
    <ArrowForwardIosIcon style={{ fontSize: "40px" }} />
  </IconButton>
);



const Continuewatching = () => {
  const sliderRef = useRef(null);
  const navigate = useNavigate();
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [hoverStates, setHoverStates] = useState({});
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const [error500, setError500] = useState(false); // Add error state for 500 status

  useEffect(() => {
    home_web()
      .then((res) => {
        const data = res.data;
        if (data && data.length > 0) {
          const recentSection = data.find(
            (section) => section.title === "Continue Watching"
          );
          if (recentSection && recentSection.list) {
            const filteredList = recentSection.list.filter((item) => {
              const watchTimeInSeconds = timeStringToSeconds(item.watch_time);
              return watchTimeInSeconds > 20;
            });

            setImages(filteredList);
            setHoverStates(Array(filteredList.length).fill(false));
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
        if (err.response && err.response.status === 500) {
          setError500(true);
        }
      })
      .finally(() => {
        setLoading(false); // Set loading to false after data is fetched
      });
  }, []);

  useEffect(() => {
    // Update arrow states based on image count
    if (images.length > 5) {
      setIsNextDisabled(false); // Enable Next if more than 6 images
    } else {
      setIsNextDisabled(true); // Disable Next if 6 or fewer
    }
    setIsPrevDisabled(true); // Always disable Prev initially
  }, [images]);
  const handleHover = (index, isHovered) => {
    setHoverStates((prevState) => ({
      ...prevState,
      [index]: isHovered,
    }));
  };

  const encryptQueryString = (queryString) => {
    const secretKey = "your-secret-key"; // Use a secure key here
    const encrypted = CryptoJS.AES.encrypt(queryString, secretKey).toString();
    return encodeURIComponent(encrypted); // Make it URL-safe
  };

  const timeStringToSeconds = (timeString) => {
    if (!timeString) return 0;
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };

  const handleClick = (content_id, content_type_id, watch_time) => {
    const watchTimeInSeconds = timeStringToSeconds(watch_time);
    const queryString = `content_id=${content_id}&content_type_id=${content_type_id}&start_time=${watchTimeInSeconds}`;
    const encryptedQuery = encryptQueryString(queryString);
    navigate(`/videoPlayer?data=${encryptedQuery}`);
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    // centerMode: images.length === 4? false : undefined, // Disable centering if only one image
    prevArrow: (
      <CustomPrevArrow
        onClick={() => sliderRef.current.slickPrev()}
        disabled={isPrevDisabled}
      />
    ),
    nextArrow: (
      <CustomNextArrow
        onClick={() => sliderRef.current.slickNext()}
        disabled={isNextDisabled}
      />
    ),
    beforeChange: (current, next) => {
      setIsPrevDisabled(next === 0);
      setIsNextDisabled(next >= images.length - 5);
    },
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };


  // const settings = {
  //   // dots: false,
  //   // infinite: false,
  //   // speed: 500,
  //   // slidesToShow: 5, // Display 5 thumbnails per row
  //   // slidesToScroll: 1,
  
  //   // initialSlide: 0,
  //   // prevArrow: <CustomPrevArrow onClick={() => sliderRef.current.slickPrev()} disabled={isPrevDisabled} />,
  //   // nextArrow: <CustomNextArrow onClick={() => sliderRef.current.slickNext()} disabled={isNextDisabled} />,
  //   // beforeChange: (current, next) => {
  //   //   setIsPrevDisabled(next === 0);
  //   //   setIsNextDisabled(next >= images.length - 5);
  //   // },
  //   dots: false,
  //   infinite: false,
  //   speed: 500,
  //   slidesToShow: 5, // Display 5 thumbnails per row
  //   slidesToScroll: 1,
  //   initialSlide: 0,
  //   // centerMode: images.length === 5? false : undefined, // Disable centering if only one image
  //   prevArrow: <CustomPrevArrow onClick={() => sliderRef.current.slickPrev()} disabled={isPrevDisabled} />,
  //   nextArrow: <CustomNextArrow onClick={() => sliderRef.current.slickNext()} disabled={isNextDisabled} />,
  //   beforeChange: (current, next) => {
  //     setIsPrevDisabled(next === 0); // Disable Prev if at the first slide
  //     setIsNextDisabled(next >= Math.ceil(images.length / 4) - 1); // Disable Next if at the last group of images
  //   },
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 5,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 3,
  //         slidesToScroll: 1,
  //       },
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  return (
    <Grid
    sx={{
      position: "relative",
      paddingTop: "1rem",
      paddingBottom: "5rem",
      paddingLeft: { xs: "2rem", sm: "2rem", md: "10rem", lg: "10rem" },
      paddingRight: { xs: "2rem", sm: "2rem", md: "10rem", lg: "10rem" },
    }}
  >
    {/* Your background blur effect */}
    <div
      style={{
        width: "200.01px",
        marginTop: "225px",
        height: "300.45px",
        left: "0%",
        top: "0%",
        gap: "0px",
        background: "rgba(118, 64, 232, 1)",
        filter: "blur(90px)",
        position: "absolute",
      }}
    />
    <Grid container columns={{ xs: 4, sm: 8, md: 12 }} sx={{ position: "relative" }}>
      <Grid item xs={12} sm={6} md={6} sx={{ paddingBottom: "1.5rem" }}>
        <Typography variant="subTitle1">Continue Watching</Typography>
      </Grid>
    </Grid>
    <Divider style={{ border: "1px solid #64748B", position: "relative" }} />

    <div className="slider-container">
      <Slider ref={sliderRef} {...settings}>
        {loading
          ? // Show Skeleton loader while loading
            Array.from(new Array(10)).map((_, index) => (
              <Grid item xs={12} sm={2} md={2} lg={2} key={index}>
                <Box
                  sx={{
                    width: "100%",
                    height: "200px",
                    position: "relative",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100%"
                  />
                </Box>
              </Grid>
            ))
          : images.map((item, index) => {
              const totalSeconds = timeStringToSeconds(item.total_duration);
              const watchedSeconds = timeStringToSeconds(item.watch_time);
              const progressPercentage = totalSeconds > 0 ? (watchedSeconds / totalSeconds) * 100 : 0;

              return (
                <div key={index}>
                  <Grid container justifyContent="center" alignItems="center" sx={{ padding: "0.5rem", paddingTop: "32px" }}>
                    <div
                      style={{
                        position: "relative",
                        overflow: "hidden",
                        width: "100%",
                        height: "auto",
                        cursor: "pointer",
                        gap: "10px",
                        borderRadius: "8px",
                      }}
                      onMouseEnter={() => handleHover(index, true)}
                      onMouseLeave={() => handleHover(index, false)}
                      onClick={() => handleClick(item.content_id, item.content_type_id, item.watch_time)}
                    >
                      <img
                        src={item.thumbnail}
                        alt={`Thumbnail ${index}`}
                        style={{
                          width: "100%",
                          borderRadius: "11.36px",
                          objectFit: "cover",
                          transition: "transform 0.3s ease-in-out",
                          transform: hoverStates[index] ? "scale(1.05)" : "scale(1)",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0",
                          width: `${progressPercentage}%`,
                          maxWidth: "100%",
                          height: "6.01px",
                          background: "#E43EFF",
                          borderRadius: "11.36px",
                          transition: "width 0.3s ease-in-out",
                        }}
                      />
                      <div
                        style={{
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-end",
                          background: hoverStates[index] ? "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(11, 4, 16, 0.39) 34.9%, #1B0A28 100%)" : "transparent",
                          opacity: hoverStates[index] ? 1 : 0,
                          transition: "opacity 0.3s ease-in-out, background 0.3s ease-in-out",
                          pointerEvents: "none",
                          padding: "1rem",
                          boxSizing: "border-box",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: { xs: "12px", sm: "12px", md: "14px", lg: "16px", xl: "18px" },
                            lineHeight: { xs: "16px", sm: "18px", md: "22px", lg: "24px", xl: "26px" },
                            color: "white",
                            fontFamily: "Inter !important",
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          {item.content_title}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: { xs: "10px", sm: "10px", md: "12px", lg: "14px", xl: "16px" },
                            lineHeight: { xs: "14px", sm: "14px", md: "16px", lg: "18px", xl: "20px" },
                            color: "white",
                            marginTop: "4px",
                            fontFamily: "Inter !important",
                            display: "-webkit-box",
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                          }}
                        >
                          {item.content_description}
                        </Typography>
                      </div>
                    </div>
                  </Grid>
                </div>
              );
            })}
      </Slider>
    </div>
  </Grid>
  );
};

export default Continuewatching;

