import { Divider, Grid, Typography } from "@mui/material";
import React from "react";

function aboutUs() {
  return (
    <div
      style={{
        maxWidth: "100vw",
        minHeight: "100vh",
        backgroundColor: "#1b0a28",
        position: "relative",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
      <Grid
        sx={{
          position: "relative",
          paddingTop: { xs: "8rem", sm: "10rem", md: "12rem", lg: "12rem" },

          paddingLeft: { sm: "2rem", md: "2rem", lg: "10rem" },
          paddingRight: { sm: "2rem", md: "2rem", lg: "10rem" },
        }}
      >
        {/* <Grid classes="content_gradient_sub"></Grid>
        <Grid classes="recent_gradient"></Grid> */}
        <div
          style={{
            width: "549px",

            borderRadius: "600.667px",

            height: "600.45px",

            left: "0%",
            top: "0%",

            gap: "0px",
            opacity: "0px",
            background: "rgba(118, 64, 232, 1)",
            filter: "blur(170px)",

            position: "absolute",
          }}
        />
        <Divider
          style={{
            border: "1px solid #64748B",
            position: "relative",
            marginTop: "2rem",
            marginBottom: "5rem",
          }}
        />
        {/* <Grid classes="home_gradients_about" /> */}
        {/* <Grid classes="home_gradients" /> */}
        {/* <Grid classes="recent_gradient_sub"></Grid> */}
        {/* <Grid classes="recent_gradient_sub_terms"></Grid> */}
        <div
          style={{
            // width: "181px",
            width: "190.201px",
            //   height: "680.82px",
            height: "800.425px",
            borderRadius: "893.425px",
            border: "1px solid #230E60",
            top: "25%",
            flexShrink: 0,
            right: "15%",
            background:
              "linear-gradient(170deg, rgba(188, 46, 255, 0.60) 9.43%, rgba(128, 76, 238, 0.00) 117.55%)",

            position: "absolute", // Position absolutely within the parent container

            // marginTop:'58rem',
            // marginTop:'65rem',
            transform: "rotate(87.26deg)",
            filter: "blur(90.56px)",

            gap: "0px",
            opacity: "0px",
          }}
        />
        <div
          style={{
            width: "279px",
            //  marginTop:'130px',

            marginTop: "1080px",

            borderRadius: "500.667px",

            //   height: "680.82px",
            //  height: "400.45px",
            height: "650.45px",
            // top: "1239px",
            left: "0%",
            top: "0%",
            // top:'780px',
            // marginTop:"150px",
            gap: "0px",
            opacity: "0px",
            background: "rgba(118, 64, 232, 1)",
            filter: "blur(190px)",
            //   zIndex:-5,
            position: "absolute",
          }}
        />
        <div
          style={{
            width: "279px",
            //  marginTop:'130px',

            marginTop: "800px",

            borderRadius: "500.667px",

            //   height: "680.82px",
            //  height: "400.45px",
            height: "650.45px",
            // top: "1239px",
            left: "0%",
            top: "20%",
            // top:'780px',
            // marginTop:"150px",
            gap: "0px",
            opacity: "0px",
            background: "rgba(118, 64, 232, 1)",
            filter: "blur(190px)",
            //   zIndex:-5,
            position: "absolute",
          }}
        />
        <Grid
          container
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ position: "relative" }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              position: "relative",
              paddingLeft: { xs: "16px", sm: "3rem", md: "3rem", lg: "5rem" },
            }}
          >
            <Typography
              variant="h8"
              sx={{
                fontSize: {
                  xs: "30px",
                  sm: "38.574px",
                  md: "38.574px",
                  lg: "38.574px",
                }, // Responsive font size
              }}
            >
              About Evangel
            </Typography>
          </Grid>

          <Grid
            sx={{
              //   paddingLeft: "8rem",
              // paddingTop: "2rem",
              //   paddingRight: "8rem",
              paddingBottom: { xs: "1rem", sm: "5rem", md: "5rem", lg: "5rem" },
              paddingTop: { xs: "0", sm: "2rem", md: "2rem", lg: "2rem" },
              paddingLeft: { xs: "16px", sm: "3rem", md: "3rem", lg: "5rem" },
              paddingRight: { xs: "16px", sm: "3rem", md: "3rem", lg: "5rem" },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "24.84px",
                  md: "24.84px",
                  lg: "24.84px",
                }, // Responsive font size
                lineHeight: { xs: "30px", sm: "39px", md: "39px", lg: "39px" },
              }}
            >
              “Evangel OTT” is a global media ministry with the vision of
              transforming lives with the Gospel of Jesus Christ. We adapt OTT,
              a leading edge technology to cater to the diverse needs of thirsty
              souls far and wide. Because our God is a God near at hand and a
              God afar off, every soul far and near deserves to hear the gospel.
              <br />
              <br />
              As we live in the end times, Gospel is to reach the nations post-
              haste. God burdened Bro. Mohan C. Lazarus and Bro. Vincent
              Selvakumar with a vision to reach nations through television and
              that birthed Comforter TV.
              <br />
              <br />
              Going a step further God has led us to set up “Evangel OTT”, a
              part of Jesus Redeems Ministries, to reach out to the lost souls,
              equip believers for the harvest and to prepare the world for the
              great end time revival and the mighty Second Coming of Jesus
              Christ. With this as our heart beat we are committed to win souls
              using media in all forms to take the Father's heart to His
              children. With the spirit of commitment and excellence, “Evangel
              OTT” sources, creates and presents anointed, soul reviving truth
              that is uncompromising with the word of God. Since, the end time
              revival is for the whole of the world, all committed ministers of
              God are encouraged to join hands in this great mission.Evangel OTT
              makes available the Gospel of Jesus Christ in all possible genres
              to anybody, anytime, anywhere.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              position: "relative",
              paddingLeft: { xs: "16px", sm: "3rem", md: "3rem", lg: "5rem" },
            }}
          >
            <Typography
              variant="h8"
              sx={{
                fontSize: {
                  xs: "30px",
                  sm: "38.574px",
                  md: "38.574px",
                  lg: "38.574px",
                }, // Responsive font size
              }}
            >
              Our Vision
            </Typography>
          </Grid>

          <Grid
            sx={{
              //   paddingLeft: "8rem",
              // paddingTop: "2rem",
              //   paddingRight: "8rem",
              paddingBottom: { xs: "1rem", sm: "5rem", md: "5rem", lg: "5rem" },
              paddingTop: { xs: "0", sm: "2rem", md: "2rem", lg: "2rem" },
              paddingLeft: { xs: "16px", sm: "3rem", md: "3rem", lg: "5rem" },
              paddingRight: { xs: "16px", sm: "3rem", md: "3rem", lg: "5rem" },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "24.84px",
                  md: "24.84px",
                  lg: "24.84px",
                }, // Responsive font size
                lineHeight: { xs: "30px", sm: "39px", md: "39px", lg: "39px" },
              }}
            >
              Our vision is to take the saving grace of our Lord Jesus Christ
              around the globe. We strive to unite all children of God on a
              single platform to evangelize the nations, build lives, transform
              societies and prepare the world for a great world-wide revival and
              the second Coming of Jesus Christ.
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              position: "relative",
              paddingLeft: { xs: "16px", sm: "3rem", md: "3rem", lg: "5rem" },
            }}
          >
            <Typography
              variant="h8"
              sx={{
                fontSize: {
                  xs: "30px",
                  sm: "38.574px",
                  md: "38.574px",
                  lg: "38.574px",
                }, // Responsive font size
              }}
            >
              Mission
            </Typography>
          </Grid>

          <Grid
            sx={{
              //   paddingLeft: "8rem",
              paddingTop: { xs: "0", sm: "2rem", md: "2rem", lg: "2rem" },

              paddingBottom: { xs: "5rem", sm: "5rem", md: "5rem", lg: "5rem" },

              paddingLeft: { xs: "16px", sm: "3rem", md: "3rem", lg: "5rem" },
              paddingRight: { xs: "16px", sm: "3rem", md: "3rem", lg: "5rem" },
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "16px",
                  sm: "24.84px",
                  md: "24.84px",
                  lg: "24.84px",
                },
                lineHeight: { xs: "30px", sm: "39px", md: "39px", lg: "39px" },
                // Responsive font size
              }}
            >
              We strive to take the love of Jesus Christ to the unreached by
              adapting all up-to-date and smart technologies to reach the smart
              world. As the great commission of Jesus Christ is not confined to
              some, we make utmost effort to unite all similarly burdened
              children of God under a platform towards catering to all spiritual
              requirements of souls. We are earnest to do whatever it takes to
              take the true word of God to multilingual audience worldwide,
              encourage them to grow more in Christ, equip them to win souls for
              Christ. “Evangel OTT” will articulate the Goods News of God’s
              Kingdom, until our Lord’s second coming.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default aboutUs;
