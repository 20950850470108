import axiosInstance from "../../axiosInstance";
import { apiEndpoint } from "../../components/data/config";

// export const home_web = async () => {
//   const config = {
//     method: "get",
//     url: `${apiEndpoint}/api/web_home_page`,

//   };
//   return axiosInstance(config); // Use axiosInstance instead of axios
// };

export const popular_search = async () => {
  const config = {
    method: "get",
    url: `${apiEndpoint}/api/app_search_list`,
    headers: {
      // Authorization: `Bearer 1384|bFJ9OOVjfQmM4i27X96ThzMIzwUFJj50vHnxSH2k4216bf9e`,

      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config); // Use axiosInstance instead of axios
};

export const home_web = async () => {
  const config = {
    method: "get",
    url: `${apiEndpoint}/api/web_home_page`,
    headers: {
      "Content-Type": "application/json",
    },
  };
  return axiosInstance(config);
};
