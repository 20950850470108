import {
  Box,
  Divider,
  Grid,
  Typography,
  Avatar,
  TextField,
  Button,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import Camera from "../images/camera.png";
import upload from "../images/upload.png";
import Delete from "../images/Delete.png";
import {
  profile_update,
  view_profile,
  Delete_profile,
} from "../../components/layout/Footer/profile/profileState";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../components/Snackbar/snackbar";
import Deletetoast from "../images/deletetoast.png";
import five from "../images/five.png";
import four from "../images/400.png";
import Loader from "../../components/loader/loader";
const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null; // Don't render the modal if not open

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 999, // Make sure modal is above everything else
        width: "250px",
        height: "250px",
        backgroundColor: "#473B51",
        borderRadius: "14px",
        boxShadow: "0px 64px 64px rgba(0, 0, 0, 0.25)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        alignItems: "center",
        padding: "19px",
        border: "1px solid #734B8E",
      }}
    >
      <Typography
        style={{
          color: "#fff",
          textAlign: "center",
          fontFamily: "Inter",
          fontSize: "21px",
          padding: 0,
        }}
      >
        Are you sure to delete the account ?
      </Typography>

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
        }}
      >
        <Button
          onClick={onConfirm}
          style={{
            backgroundColor: "#FF5DEF",
            color: "#fff",
            borderRadius: "8px",
            padding: "8px 16px",
          }}
        >
          OK
        </Button>
        <Button
          onClick={onClose}
          style={{
            backgroundColor: "#734B8E",
            color: "#fff",
            borderRadius: "8px",
            padding: "8px 16px",
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

const Account = () => {
  const [showOptions, setShowOptions] = useState(false);
  const [name, setName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [image, setImage] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState();
  const [email, setEmail] = useState(""); // <-- New State for Email
  const [mobileNumber, setMobileNumber] = useState(""); // <-- New State for Mobile Number
  const fileInputRef = useRef(null);
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const navigate = useNavigate();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [error500, setError500] = useState(false); // Add error state for 500 status
  const [error404, setError404] = useState(false); // Add error state for 500 status
  const [loading, setLoading] = useState(false); // Manage loader visibility
  const handleClickSnackbar = (message, imgSrc) => {
    setSnackbarMessage(message);
    setSnackbarImage(imgSrc); // Set the dynamic image
    setSnackbarOpen(true);
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false); // Close the snackbar
  };

  const [snackbarImage, setSnackbarImage] = React.useState(""); // Dynamic image URL
  useEffect(() => {
    const authToken = localStorage.getItem("auth_token"); // Get the auth token from localStorage
    if (!authToken) {
      navigate("/"); // Redirect to the landing page if token is not present
    }
  }, [navigate]);

  // Fetch profile data on component mount
  useEffect(() => {
    const fetchProfileData = async () => {
      try {
        const response = await view_profile();
        const data = response.data;
        setName(data.name || "");
        setBirthDate(data.birth_date || "");
        setProfileImageUrl(data?.profile_picture); // Use the profile picture URL or default avatar
        setEmail(data.email || ""); // <-- Set email from API response
        setMobileNumber(data.mobile_number || ""); // <-- Set mobile number from API response
      } catch (err) {
        console.error("Failed to fetch profile data:", err);
        if (err.response && err.response.status === 500) {
          setError500(true);
        } else if (err.response.status === 404) {
          setError404(true); // Handle 404 error
        } else if (err.response.status === 401) {
          localStorage.removeItem("auth_token");
          navigate("/");

          // Optionally reload the page to ensure UI state updates
          window.location.reload();
        }
      }
    };

    fetchProfileData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const handleImageChange = (e) => {
  //   setImage(e.target.files[0]);
  //   setShowOptions(false);
  // };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file); // Store the selected file
      setProfileImageUrl(URL.createObjectURL(file)); // Create a preview URL for the image
      setShowOptions(false); // Hide options after upload
    }
  };
  const handleDeleteImage = () => {
    setImage(null); // Remove the image from state
    setProfileImageUrl(Avatar); // Show default avatar
  };

  const handleAvatarClick = () => {
    setShowOptions(!showOptions);
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleSaveSettings = async () => {
    setLoading(true); // Show the loader when the function is called

    const formData = new FormData();
    formData.append("name", name);
    formData.append("birth_date", birthDate);
    // if (image) {
    //   formData.append("image", image);
    // }

    if (image) {
      formData.append("image", image); // If the user selected a new image, upload it
    } else {
      formData.append("delete_image", true); // Signal the backend to delete the image if null
    }

    console.log("FormData contents:");
    formData.forEach((value, key) => {
      console.log(key, value);
    });

    try {
      const response = await profile_update(formData);
      console.log("Profile update response:", response);

      if (response.status === 200) {
        // Fetch updated profile data
        const updatedProfile = await view_profile();
        setName(updatedProfile.data.name || "");
        setBirthDate(updatedProfile.data.birth_date || "");
        setProfileImageUrl(updatedProfile.data.profile_picture);

        // Display Snackbar with success message
        handleClickSnackbar("Profile updated successfully!");
        setLoading(false); // Hide the loader after the request is completed
      }
    } catch (error) {
      console.error("Failed to update profile:", error);
      // Display Snackbar with error message
      handleClickSnackbar("Failed to update profile", Deletetoast);
    }finally {
    setLoading(false); // Hide the loader after operation completes
  }
  };

  // const handleDeleteProfile = async () => {
  //   const confirmed = window.confirm("Are you sure you want to delete your profile?");
  //   if (!confirmed) return;

  //   try {
  //     await Delete_profile();
  //     alert("Profile deleted successfully");
  //   } catch (error) {
  //     console.error("Failed to delete profile:", error.response?.data || error.message);
  //     alert(`Failed to delete profile: ${error.response?.data || "Please try again."}`);
  //   }

  // };

  const handleDeleteProfile = async () => {
    try {
      await Delete_profile(); // Assume this is the API call to delete the profile

      localStorage.removeItem("auth_token");
      handleClickSnackbar("profile deleted successfully!", Deletetoast);
      navigate("/"); // Redirect to landing page
    } catch (error) {
      console.error(
        "Failed to delete profile:",
        error.response?.data || error.message
      );
      alert(
        `Failed to delete profile: ${
          error.response?.data || "Please try again."
        }`
      );
      setSnackbarMessage("Failed to delete profile", Deletetoast);
      setSnackbarOpen(true);
    }
  };

  const handleDeleteClick = () => {
    setShowModal(true); // Show modal when delete is clicked
  };

  const handleModalClose = () => {
    setShowModal(false); // Close modal
  };

  const handleModalConfirm = () => {
    setShowModal(false); // Close modal
    handleDeleteProfile(); // Call the delete profile function
  };

  return (
  
    <div
      style={{
        maxWidth: "100vw",
        height: "100%",
        minHeight: "100vh",
        backgroundColor: "#1b0a28",
        position: "relative",
        overflowX: "hidden",
        overflowY: "hidden",
      }}
    >
   {loading && (
      <div className="loader-container">
        <Loader />
      </div>)}
   
      {error500 && (
        <div
          style={{
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh", // Full viewport height to center the image vertically
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            zIndex: 10, // Ensure it appears above the normal content
            display: "flex",
          }}
        >
          <img
            src={five}
            alt="Error 500"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      )}

      {/* Render 404 Error Image */}
      {error404 && (
        <div
          style={{
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh", // Full viewport height to center the image vertically
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            zIndex: 10, // Ensure it appears above the normal content
            display: "flex",
          }}
        >
          <img
            src={four}
            alt="Error 404"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
      )}
      {!error500 && !error404 && (
        <>
          <Grid
            sx={{
              position: "relative",
              paddingTop: { xs: "8rem", sm: "10rem", md: "12rem", lg: "12rem" },
              paddingLeft: { xs: "1rem", sm: "2rem", md: "5rem", lg: "10rem" },
              paddingRight: { xs: "1rem", sm: "2rem", md: "5rem", lg: "10rem" },
            }}
          >
            {/* <Grid className="content_gradient_sub"></Grid> */}
            {/* <Grid className="recent_gradient"></Grid> */}
            <div
              style={{
                width: "549px",

                borderRadius: "600.667px",

                height: "600.45px",

                left: "0%",
                top: "0%",

                gap: "0px",
                opacity: "0px",
                background: "rgba(118, 64, 232, 1)",
                filter: "blur(170px)",

                position: "absolute",
              }}
            />
            <div
              style={{
                width: "549px",

                borderRadius: "600.667px",

                height: "600.45px",

                left: "0%",
                top: "0%",

                gap: "0px",
                opacity: "0px",
                background: "rgba(118, 64, 232, 1)",
                filter: "blur(170px)",

                position: "absolute",
              }}
            />
            {/* <Grid classes="home_gradients_nodata" /> */}
            <div
              style={{
                // display: { xs: "flex", sm: "none", md: "none", lg: "none", xl: "none" } ,
                // width: "181px",
                width: "190.201px",
                //   height: "680.82px",
                height: "800.425px",
                borderRadius: "893.425px",
                border: "1px solid #230E60",
                top: "40%",
                flexShrink: 0,
                right: "15%",
                background:
                  "linear-gradient(170deg, rgba(188, 46, 255, 0.60) 9.43%, rgba(128, 76, 238, 0.00) 117.55%)",

                position: "absolute", // Position absolutely within the parent container

                // marginTop:'58rem',
                // marginTop:'65rem',
                transform: "rotate(87.26deg)",
                filter: "blur(90.56px)",

                gap: "0px",
                opacity: "0px",
              }}
            />
            {/* <Grid classes="recent_gradient_sub"></Grid> */}
            <div
              style={{
                width: "279px",
                //  marginTop:'130px',

                marginTop: "1080px",

                borderRadius: "500.667px",

                //   height: "680.82px",
                //  height: "400.45px",
                height: "650.45px",
                // top: "1239px",
                left: "0%",
                top: "0%",
                // top:'780px',
                // marginTop:"150px",
                gap: "0px",
                opacity: "0px",
                background: "rgba(118, 64, 232, 1)",
                filter: "blur(190px)",
                //   zIndex:-5,
                position: "absolute",
              }}
            />

            <Grid
              container
              columns={{ xs: 12, sm: 8, md: 12 }}
              sx={{ position: "relative" }}
            >
              <Grid item xs={12} sm={6} md={6} sx={{ position: "relative" }}>
                <Typography variant="subTitle1">Account Settings</Typography>
              </Grid>
            </Grid>

            <Divider
              style={{
                border: "1px solid #64748B",
                position: "relative",
                marginTop: "2rem",
              }}
            />

            <Grid
              container
              columns={{ xs: 12, sm: 8, md: 12, lg: 12, xl: 12 }}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{
                paddingBottom: {
                  xs: "8rem",
                  sm: "10rem",
                  md: "5rem",
                  lg: "5rem",
                },
                paddingTop: { xs: "8rem", sm: "10rem", md: "5rem", lg: "5rem" },
              }}
            >
              <Grid item xs={0} sm={6} md={0} lg={0} xl={1} />

              <Grid item xs={12} sm={10} md={12} lg={12} xl={12}>
                <Box
                  sx={{
                    display: "flex",
                    // justifyContent: "center",
                    justifyContent: {
                      xs:"start",
                      sm:"center",
                      md:"center",
                      lg:"center",
                      xl:"center",
                    },
                    alignItems: "center",
                    minHeight: "10vh",
                    width: "100%",
                    position: "relative", // Important for absolute positioning inside
                  }}
                >
                  <Avatar
                    alt="Profile Image"
                    src={profileImageUrl}
                    sx={{
                      width: "100px",
                      height: "100px",
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "37.13px",
                    height: "37.13px",
                    position: "absolute",
                    // left: "53%", // Positioning it to the right of the avatar
                    left: {
                      xs:"30%",
                      sm:"58%",
                      md:"53%",
                      lg:"53%",
                      xl:"53%"
                    }, // Positioning it to the right of the avatar
                    transform: "translate(-50%, -50%)", // Adjusting for perfect alignment
                    background: "rgba(217, 217, 217, 1)",
                    borderRadius: "50%", // Makes the box circular
                    opacity: 1, // Set to 1 for visibility
                    cursor: "pointer",
                  }}
                  onClick={handleAvatarClick} // Handle click event
                >
                  <img
                    src={Camera}
                    alt="camera"
                    style={{
                      padding: "8px",
                    }}
                  />
                </Box>
                {showOptions && ( // Conditionally render this box
                  <Box
                    sx={{
                      width: "245px",
                      height: "121px",
                      position: "absolute", // Use absolute to control positioning
                    //  top: "38%", // Aligns the top with the center of the Avatar

                      right: {
                        xs: "2%",
                        sm: "8%",
                        md: "15%",
                        lg: "20%",
                        xl: "27%",
                      },
                      top: {
                        xs: "28%",
                        sm: "34%",
                        md: "38%",
                        lg: "38%",
                        xl: "38%",
                      },
                      transform: "translateY(-50%)", // Center vertically
                      padding: "14px 20.5px",
                      borderRadius: "30px",
                      background: "rgba(70, 52, 85, 1)",
                      boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                      opacity: 1, // Ensure visibility
                      display: "flex",
                      flexDirection: "column", // Stack children vertically
                      justifyContent: "center", // Center children vertically
                      alignItems: "center", // Center children horizontally
                      gap: "10px", // Space between Upload and Delete Photo
                    }}
                    
                  >
                    <Typography
                      sx={{
                        display: "flex",
                        padding: "5.928px 11.857px",
                        alignItems: "center",
                        gap: "11.857px",
                        // justifyContent: "center", // Center content horizontally

                        borderRadius: "7.905px",
                        borderBottom: "0.988px solid rgba(244, 244, 244, 0.20)",
                        color: "var(--neutral-white-high-emp, #FFF)",
                        textAlign: "center",
                        fontFeatureSettings: "'liga' off, 'clig' off",
                        fontFamily: "Inter",
                        fontSize: "13.833px",
  cursor:"pointer",
                        fontWeight: 500,
                        lineHeight: "130%" /* 17.983px */,
                      }}
                      onClick={handleUploadClick}
                    >
                      <img
                        src={upload}
                        alt="upload"
                        onClick={handleUploadClick} // Trigger file input click
                      />
                      Upload
                      <input
                        type="file"
                        ref={fileInputRef}
                        id="upload-photo"
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleImageChange}
                      />
                    </Typography>

                    <Typography
                      sx={{
                        display: "flex",
                        padding: "5.928px 11.857px",
                        alignItems: "center",
                        gap: "11.857px",

                        borderRadius: "7.905px",
                        // justifyContent: "center", // Center content horizontally
                        color: "#CD59E2",
                        textAlign: "center",
                        fontFeatureSettings: "'liga' off, 'clig' off",
                        fontFamily: "Inter",
                        fontSize: "13.833px",

                        fontWeight: 500,
                        lineHeight: "130%" /* 17.983px */,
                        cursor:"pointer"
                      
                      }}
                      onClick={handleDeleteImage} // Add image delete handler
                    >
                      <img src={Delete} alt="Delete" />
                      Delete Photo
                    </Typography>
                  </Box>
                )}
                {/* Wishlist Text */}
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: 700,
                    // lineHeight: "32px",
                    letterSpacing: "0.005em",
                    // textAlign: "center",
                    textAlign:{
                      xs:"start",
                      sm:"center",
                      md:"center",
                      lg:"center",

                    } ,
                    lineHeight:{
                      xs:"45px",
                      sm:"32px",
                      md:"32px",
                      lg:"32px",

                    } ,
                    
                   
                    background:
                      "radial-gradient(187.9% 485.46% at 6.88% 15.79%, #FFFFFF 13.11%, #702CFF 38.28%)",
                    backgroundClip: "text",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                    backgroundBlendMode: "color",
                    position:"relative"
                  }}
                >
                  PERSONAL INFORMATION
                </Typography>
                <Box
                  sx={{
                    width: {
                      xs: "360px",
                      sm: "700px",
                      md: "800px",
                      lg: "800px",
                      xl: "1120px",
                    },

                    flexShrink: 0,
                  }}
                >
                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      color: "#F4F4F4",
                      fontFamily: "Inter",
                      fontSize: "20px",
                      position: "relative",
                      fontWeight: 700,
                      lineHeight: "normal",
                      letterSpacing: "3.6px",
                    }}
                  >
                    USER NAME
                  </Typography>

                  <TextField
                    placeholder="Enter User Name"
                    variant="outlined"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    sx={{
                      display: "flex",
                      height: "58.936px", // Increased height for better spacing
                      padding: "11.556px 13.867px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "-1.156px",
                      borderRadius: "46.224px;",
                      marginBottom: "1.5rem", // Adds the space between TextFields
                      backgroundColor: "#FFF",
                      alignSelf: "stretch",

                      background:
                        "var(--White-White-10, rgba(249, 249, 249, 0.10))",
                      "& .MuiInputBase-input": {
                        color: "#ffffff",
                        caretColor: "#ffffff",
                        padding: "16px",
                        boxSizing: "border-box",
                        height: "100%",
                      },
                      "& .MuiInputBase-root": {
                        padding: "0", // Removes default padding from TextField input
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent", // Removes the default border
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent", // Removes hover border
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent", // Removes focus border
                        },
                      },
                    }}
                    InputProps={{
                      sx: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%", // Ensures input field stretches to full height
                      },
                    }}
                  />

                  <Typography
                    sx={{
                      textTransform: "uppercase",
                      color: "#F4F4F4",
                      fontFamily: "Inter",
                      fontSize: "20px",
                      position: "relative",
                      fontWeight: 700,
                      lineHeight: "normal",
                      letterSpacing: "3.6px",
                    }}
                  >
                    DATE OF BIRTH
                  </Typography>

                  <TextField
                    placeholder="YYYY-MM-DD"
                    variant="outlined"
                    fullWidth
                    value={birthDate}
                    onChange={(e) => setBirthDate(e.target.value)}
                    sx={{
                      display: "flex",
                      height: "58.936px", // Increased height for better spacing
                      padding: "11.556px 13.867px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "-1.156px",
                      borderRadius: "46.224px;",
                      marginBottom: "2rem",
                      backgroundColor: "#FFF",
                      alignSelf: "stretch",

                      background:
                        "var(--White-White-10, rgba(249, 249, 249, 0.10))",

                      "& .MuiInputBase-root": {
                        padding: "0", // Removes default padding from TextField input
                      },
                      "& .MuiInputBase-input": {
                        color: "#ffffff",
                        caretColor: "#ffffff",
                        padding: "16px",
                        boxSizing: "border-box",
                        height: "100%",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent", // Removes the default border
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent", // Removes hover border
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent", // Removes focus border
                        },
                      },
                    }}
                    InputProps={{
                      sx: {
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%", // Ensures input field stretches to full height
                      },
                    }}
                  />

                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontWeight: 700,
                      letterSpacing: "0.2px",
                      color: "#F4F4F4",
                      padding: "0",
                      textTransform: "uppercase",
                      marginBottom: "0.5rem",
                    }}
                  >
                    Email Address :{" "}
                    <Box
                      component="span"
                      sx={{
                        color: "#A3A3A3",
                        fontFamily: "Inter",
                        fontSize: "22px",
                        fontWeight: 500,
                        letterSpacing: "-0.22px",
                        lineHeight: "normal",
                        textTransform: "none", // Override uppercase if needed
                      }}
                    >
                      {email || "No Email Available"}
                    </Box>
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      fontSize: "20px",
                      fontWeight: 700,
                      letterSpacing: "0.2px",
                      color: "#F4F4F4",
                      padding: "0",
                      textTransform: "uppercase",
                      marginBottom: "4rem",
                    }}
                  >
                    Phone Number :{" "}
                    <Box
                      component="span"
                      sx={{
                        color: "#A3A3A3",
                        fontFamily: "Inter",
                        fontSize: "22px",
                        fontWeight: 500,
                        letterSpacing: "-0.22px",
                        lineHeight: "normal",
                        textTransform: "none", // Override uppercase if needed
                      }}
                    >
                      {mobileNumber || "No Phone Number Available"}
                    </Box>
                  </Typography>
                </Box>
                {/* <Box
                  sx={{
                    display: "flex",
                  }}
                >
                  <Button
                    onClick={handleSaveSettings}
                    sx={{
                      color: "#FF5DEF",
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 700,
                      display: "flex",
                      width: "322px",
                      height: "51px",
                      padding: "16px 100px",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "var(--Container-padding, 10px)",
                      flexShrink: 0,
                      borderRadius: "30px",
                      border: "1px solid #FFF",
                      boxShadow: "0px 10px 30px 0px #1B0C57",
                      backdropFilter: "blur(5px)",
                      textTransform: "inherit",
                    }}
                  >
                    Save Settings
                  </Button>

                  <Button
                    // onClick={handleDeleteProfile}
                    onClick={handleDeleteClick} // Trigger modal on delete click
                    sx={{
                      fontFeatureSettings: "'liga' off, 'clig' off",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontWeight: 700,
                      display: "flex",
                      width: "322px",
                      height: "51px",
                      padding: "16px 100px",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "var(--Container-padding, 10px)",
                      flexShrink: 0,
                      borderRadius: "30px",
                      border: "1px solid transparent",

                      boxShadow: "0px 10px 30px 0px #1B0C57",
                      backdropFilter: "blur(5px)",
                      textTransform: "inherit",
                      background:
                        "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
                      color: "#fff",
                      marginLeft: "5rem",
                    }}
                  >
                    Delete Profile
                  </Button>
                </Box> */}
                <Box
  sx={{
    display: "flex",
    flexDirection: {
      xs: "column", // For small screens, buttons will be stacked vertically
      md: "row",
      sm:"column"    // For medium and larger screens, buttons will be side by side
    },
    gap: "10px", // Adds some space between the buttons
  }}
>
  <Button
    onClick={handleSaveSettings}
    sx={{
      color: "#FF5DEF",
      fontFeatureSettings: "'liga' off, 'clig' off",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 700,
      display: "flex",
      width: {
        xs: "100%", // Full width on small screens
        md: "322px", // Fixed width on medium and larger screens
      },
      height: "51px",
      padding: "16px 100px",
      flexDirection: "column",
      alignItems: "center",
      gap: "var(--Container-padding, 10px)",
      flexShrink: 0,
      borderRadius: "30px",
      border: "1px solid #FFF",
      boxShadow: "0px 10px 30px 0px #1B0C57",
      backdropFilter: "blur(5px)",
      textTransform: "inherit",
    }}
  >
    Save Settings
  </Button>

  <Button
    onClick={handleDeleteClick} // Trigger modal on delete click
    sx={{
      fontFeatureSettings: "'liga' off, 'clig' off",
      fontFamily: "Inter",
      fontSize: "16px",
      fontWeight: 700,
      display: "flex",
      width: {
        xs: "100%", // Full width on small screens
        md: "322px", // Fixed width on medium and larger screens
      },
      height: "51px",
      padding: "16px 100px",
      flexDirection: "column",
      alignItems: "center",
      gap: "var(--Container-padding, 10px)",
      flexShrink: 0,
      borderRadius: "30px",
      border: "1px solid transparent",
      boxShadow: "0px 10px 30px 0px #1B0C57",
      backdropFilter: "blur(5px)",
      textTransform: "inherit",
      background:
        "linear-gradient(to left,#B429DA, #3D1965) padding-box,linear-gradient(80deg, #7A54AA,#F259F2) border-box",
      color: "#fff",
      marginLeft: {
        xs: "0",   // No margin on small screens
        md: "5rem", // Margin for larger screens
      },
    }}
  >
    Delete Profile
  </Button>
</Box>

              </Grid>

              <Grid item xs={0} sm={1} md={0} lg={0} xl={1} />
            </Grid>
          </Grid>
          <ConfirmationModal
            isOpen={showModal} // Show modal if true
            onClose={handleModalClose} // Handle close action
            onConfirm={handleModalConfirm} // Handle confirm action
          />
          <CustomSnackbar
            open={snackbarOpen}
            onClose={handleCloseSnackbar}
            message={snackbarMessage}
            autoHideDuration={5000}
            imgSrc={snackbarImage} // Dynamically pass the image URL
          />
        </>
      )}
    </div>
  );
};


export default Account;
