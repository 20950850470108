import { Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { web_landingpage } from "./landingState";

import AppleComputers from "../images/Apple display.png";

const Content = () => {
  const [content, setContent] = useState(null);

  useEffect(() => {
    web_landingpage()
      .then((res) => {
        
        const data = res.data;
        if (data && data.length > 0) {
          const desiredSection = data.find(
            (section) => section.template_type === "template_2"
          );
          if (desiredSection) {
            setContent(desiredSection);
          }
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, []);

  if (!content) return null;

  return (
    <>
      <Grid
        sx={{
          position: "relative",
          // paddingTop: "5rem",
          paddingBottom: "5rem",
          paddingTop: {
            xs: "0rem",
            sm: "5rem",
            md: "5rem",
            lg: "5rem",
            xl: "5rem",
          },
        }}
      >
        {/* Blurred container */}
        <div
          className="content_gradient"
          style={{
            width: "300.4px",
            //   height: "680.82px",
            height: "300px",
            // top: "1px",
            // left: "-218px",
            left: "0",
            bottom: "0%",
            gap: "0px",
            opacity: "0px",
            background: "rgba(118, 64, 232, 1)",
            filter: "blur(180px)",
            //   zIndex:-5,
            position: "absolute",
          }}
        ></div>
        <Container>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={6} md={6}>
              <Typography
                sx={{
                  fontFamily: "Inter !important",
                  fontSize: "42px",
                  fontWeight: 600,
                  lineHeight: "55px",
                  background:
                    "radial-gradient(187.9% 485.46% at 6.88% 15.79%, #FFFFFF -0.11%, #702CFF 46.28%)",

                  backgroundBlendMode: "color",
                  backgroundClip: "text",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  position: "relative",
                  "@media (max-width: 425px)": {
                    fontSize: "26px",
                    lineHeight: "46px",
                  },
                }}
              >
                {/* Don't Miss Latest Gospel & Prophecies to Pray */}
                {content.title}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter !important",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "32.004px",
                  color: "var(--white, #FFF)",
                  position: "relative",
                  marginTop: "6px",
                }}
              >
                {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br/>
      Feugiat nulla suspendisse tortor aene. */}
                {content.title2}
              </Typography>

              <Grid
                container
                // spacing={{ xs: 2, md: 3 }}
                columns={{ xs: 6, sm: 6, md: 6 }}
                sx={{ paddingTop: "16px" }}
              >
                {content.list.map((item, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={2} md={1} lg={1} xl={1} sm={1}>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "25px",
                          width: "16px",
                          height: "16px",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "135.76px",
                          background: "#CD03EE",
                        }}
                      ></div>
                    </Grid>
                    <Grid item xs={12} md={5} sm={5} lg={5} xl={5} sx={{
                     
                    }}>
                      <Typography
                        sx={{
                          color: "var(--white, #FFF)",
                          fontFamily: "Inter",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          lineHeight: "32.004px",
                          // marginTop: '10px',
                          position: "relative",
                        
                          right: {
                            xs: "0",   // No right positioning for small screens
                            sm: "3rem",   // No right positioning for medium screens
                            md: "3rem",   // No right positioning for medium screens
                            lg: "3rem", // Apply right 3rem for large screens and above
                          },

                        }}
                      >
                        {item}
                      </Typography>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "flex",
                  xl: "none",
                },
                position: "relative", // This allows the child to be absolutely positioned inside it
              }}
            >
              <img
                src={AppleComputers}
                alt="framimage"
                style={{
                  // width: "100%",
                  // height: "auto",
                  objectFit: "contain", // Ensure the image maintains its aspect ratio
                }}
              />
              <img
                src={content.image}
                alt="AppleComputers"
                style={{
                  width: "145%",
                  minWidth: "97%",
                  height: "83%",
                  objectFit: "cover", // Ensure the image maintains its aspect ratio
                  position: "absolute",
                  top: "6%", // Adjust as needed
                  // left: "21%", // Adjust as needed
                  marginLeft:"98px",
                  // width: "70%", // Adjust as needed to fit the frame
                  // height: "auto", // Maintain aspect ratio
                  borderRadius: "6px",
                  zIndex: 1, // Ensure the image is above the frame
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "none",
                  xl: "none",
                },
                position: "relative", // This allows the child to be absolutely positioned inside it
              }}
            >
              <img
                src={AppleComputers}
                alt="framimage"
                style={{
                  // width: "100%",
                  // height: "auto",
                  objectFit: "contain", // Ensure the image maintains its aspect ratio
                }}
              />
              <img
                src={content.image}
                alt="AppleComputers"
                style={{
                  width: "145%",
                  minWidth: "97%",
                  height: "83%",
                  objectFit: "cover", // Ensure the image maintains its aspect ratio
                  position: "absolute",
                  top: "6%", // Adjust as needed
                  // left: "24%", // Adjust as needed
                  marginLeft:"98px",
                  // width: "70%", // Adjust as needed to fit the frame
                  // height: "auto", // Maintain aspect ratio
                  borderRadius: "6px",
                  zIndex: 1, // Ensure the image is above the frame
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "none",
                  xl: "flex",
                },
                position: "relative", // Position relative for the container
                left: "12%",
              }}
            >
              <img
                src={AppleComputers}
                alt="framimage"
                style={{
                  // width: "100%",
                  // height: "auto",
                  objectFit: "contain", // Ensure the image maintains its aspect ratio
                }}
              />
              <img
                src={content.image}
                alt="AppleComputers"
                style={{
                  width: "145%",
                  minWidth: "84%",
                  height: "83%",
                  objectFit: "cover", // Ensure the image maintains its aspect ratio
                  position: "absolute",
                  top: "6%", // Adjust as needed
                  // left: "21%", // Adjust as needed
                  marginLeft:"98px",
                  borderRadius: "5px",
                  zIndex: 1, // Ensure the image is above the frame
                }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              
              sx={{
                display: {
                  xs: "flex",
                  sm: "none",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <img
                src={AppleComputers}
                alt="framimage"
                style={{
                  // width: "100%",
                   height: "290px",
                  objectFit: "cover", // Ensure the image maintains its aspect ratio
                }}
              />
              <img
                src={content.image}
                alt="AppleComputers"
                style={{
                  width: "100%",
                  minWidth: "16%",
                  height: "26.2%",
                  objectFit: "cover", // Ensure the image maintains its aspect ratio
                  position: "absolute",
                  // top: "62.1%", // Adjust as needed
                  // left: "26%", // Adjust as needed
                  marginLeft: "3rem",
                  marginTop:"4px",
                  // width: "70%", // Adjust as needed to fit the frame
                  // height: "auto", // Maintain aspect ratio
                  borderRadius: "3px",
                  zIndex: 1, // Ensure the image is above the frame
                }}
              />
              {/* <img
                src={content.image}
                alt="AppleComputers"
                style={{ height: "auto", position: "relative", left: "-2%",width:"406px" }}
              /> */}
            </Grid>




            
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <img
                src={AppleComputers}
                alt="framimage"
                style={{
                  // width: "100%",
                  // height: "auto",
                  objectFit: "contain", // Ensure the image maintains its aspect ratio
                }}
              />
              <img
                src={content.image}
                alt="AppleComputers"
                style={{
                  width: "100%",
                  minWidth: "97%",
                  height: "44%",
                  objectFit: "cover", // Ensure the image maintains its aspect ratio
                  position: "absolute",
                  top: "44.5%", // Adjust as needed
                  // left: "16%", // Adjust as needed
                  marginLeft:"99px",
                  // width: "70%", // Adjust as needed to fit the frame
                  // height: "auto", // Maintain aspect ratio
                  borderRadius: "9px",
                  zIndex: 1, // Ensure the image is above the frame
                }}
              />
            </Grid>


       
          </Grid>
        </Container>
      </Grid>
    </>
  );
};

export default Content;
